const testimonyData = [
  {
    personImage: '/assets/images/Testimonial/author/puneet.png',
    testimony: '“With the launch of stock & ETF transactions, we not only provide our users exclusive trading recommendations, independent equity analysis, and actionable investment ideas but also an opportunity to buy and sell.”',
    name: 'Puneet Singhvi, President',
    firmLogo: '/assets/images/Testimonial/firms/network18.png',
  },
  {
    personImage: '/assets/images/Testimonial/author/anupam.png',
    testimony: '“Algosmith, our research platform is powered by smallcase gateway. Smallcase helped us onboard multiple brokers with their single API, saving time and effort in integrating otherwise complex workflows very seamlessly.”',
    name: 'Anupam Singhi, CEO',
    firmLogo: '/assets/images/Testimonial/firms/williamoneil.png',
  },
  {
    personImage: '/assets/images/Testimonial/author/swarup.png',
    testimony: '“smallcase Gateway has been a crucial partner in our endeavour to drive more retail participation in Mirae\'s ETF products. The ability for investors to transact directly through their broking account has enabled a seamless investor experience.”',
    name: 'Swarup Mohanty, CEO',
    firmLogo: '/assets/images/Testimonial/firms/mirae.png',
  },
  {
    personImage: '/assets/images/Testimonial/author/vishal.png',
    testimony: '“smallcase Gateway enables Nippon India to drive retail ETF adoption by powering transactions through top brokers, natively on our ETF platform. It gives investors an integrated experience where they can learn & transact on the same platform.”',
    name: 'Vishal V Jain, Head - ETF',
    firmLogo: '/assets/images/Testimonial/firms/nippon.png',
  },
  {
    personImage: '/assets/images/Testimonial/author/srinivasan.png',
    testimony: '“We have been able to launch an online ETF investment platform for retail investors, through our integration with smallcase Gateway on our website. We are now able to offer now offer direct integration with India\'s top brokerages.”',
    name: 'Srinivasan jain, COO',
    firmLogo: '/assets/images/Testimonial/firms/sbimf.png',
  },
];

// add dot btns as per no. of testimonials
testimonyData.forEach((_, idx) => {
  const dotBtnEle = document.createElement('span');
  dotBtnEle.classList.add('dot-btn');
  dotBtnEle.dataset.testimonialidx = idx;
  document
    .getElementById('testimonial-carousel')
    .appendChild(dotBtnEle);
});

// state var to track currently visible testimony
let testimonyIdx = 0;

// html element with className 'testimonial'
const testimonialCardEle = Array.from(document.getElementsByClassName('testimonial_card'))[0];

// function that updates currently visible testimony
// either use idx parameter, if available
// else increment counter by 1
function updateCarousel(idx) {
  if (idx !== undefined) testimonyIdx = idx;
  else testimonyIdx = (testimonyIdx + 1) % testimonyData.length;
  testimonialCardEle.classList.add('testimonial-transitioning');
  setTimeout(() => {
    document.getElementById('testimony').innerHTML = testimonyData[testimonyIdx].testimony;
    document.getElementById('testimony-author').innerHTML = testimonyData[testimonyIdx].name;
    document.getElementById('testimony-person-img').setAttribute('src', testimonyData[testimonyIdx].personImage);
    if (testimonyData[testimonyIdx].firmLogo) {
      document
        .getElementById('testimony-author-firm-img')
        .setAttribute('src', testimonyData[testimonyIdx].firmLogo);
    }
    Array
      .from(document.getElementsByClassName('dot-btn'))
      .forEach(dotBtnElement => {
          if (testimonyIdx == parseInt(dotBtnElement.dataset.testimonialidx)) dotBtnElement.classList.add('dot-btn-current');
          else dotBtnElement.classList.remove('dot-btn-current');
      });
      testimonialCardEle.classList.remove('testimonial-transitioning')
  }, 200);
}

// manually call the updateCarousel method first time
updateCarousel(0);

// set interval to periodically update carousel
let carouselInterval = setInterval(updateCarousel, 6000);

// function to reset interval
function resetInverval() {
  clearInterval(carouselInterval)
  carouselInterval = setInterval(updateCarousel, 6000);
}

// onClick handler for carousel's dot btn
function carouselDotBtnClickHandler(evt) {
  const idx = evt.target.dataset.testimonialidx;
  resetInverval();
  updateCarousel(parseInt(idx));
}

// attach onclick handler to each dot btn
Array
.from(document.getElementById('testimonial-carousel').getElementsByClassName('dot-btn'))
.forEach(dotBtnElement => {
  dotBtnElement.addEventListener("click", carouselDotBtnClickHandler);
});

// attach onclick handler to previous arrow
const prevCarouselEle = document.getElementById('carousel-previous');
if (prevCarouselEle) {
  prevCarouselEle.addEventListener("click", function() {
    resetInverval();
    updateCarousel((testimonyIdx - 1) < 0 ? testimonyData.length - 1 : testimonyIdx - 1);
  });
}

// attach onclick handler to next arrow
const nextCarouselEle = document.getElementById('carousel-next');
if (nextCarouselEle) {
  nextCarouselEle.addEventListener("click", function() {
    resetInverval();
    updateCarousel((testimonyIdx + 1) % testimonyData.length);
  });
}
