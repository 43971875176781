window.onscroll = function () {
  myFunction();
};

function myFunction() {
  if (
    document.body.scrollTop > 100 ||
    document.documentElement.scrollTop > 100
  ) {
    document.getElementById("header").classList.add("header-overlay");
  } else {
    document.getElementById("header").classList.remove("header-overlay");
  }
}
myFunction();

function delayAnimationBy5() {
  setTimeout(() => {
    if (document.getElementById("hideAfter5"))
      document.getElementById("hideAfter5").style.transform =
        "translateX(-1200%)";
  }, 3000);
}
delayAnimationBy5();

function triggerTypeForm(e) {
  e.preventDefault();
  const email = e.target[0].value;
  if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    e.target[0].classList.remove("border-red");
    window.open("https://tally.so/r/mDBK0N?email=" + email, "_blank");
  } else {
    e.target[0].classList.remove("border-red");
    setTimeout(() => e.target[0].classList.add("border-red"), 16);
  }
}

Array.from(document.getElementsByClassName("trigger-typeform")).forEach(
  (domElement) => {
    domElement.addEventListener("submit", triggerTypeForm);
  }
);

/* modal - see details for seamless integration */
const bodyElem = document.querySelector("body");
const seeDetailsModal = document.querySelector("#see-details-modal");
const seeDetailsModalOverlay = document.querySelector(
  "#see-details-modal-overlay"
);
const seeDetailsCloseBtn = document.querySelector("#see-details-close-button");

function toogleSeeDetailsClosed() {
  seeDetailsModal.classList.toggle("closed");
  seeDetailsModalOverlay.classList.toggle("closed");
}

Array.from(document.getElementsByClassName("see-details-btn")).forEach(
  (domElement) => {
    domElement.addEventListener("click", seeDetailsHandler);
  }
);

function seeDetailsHandler(e) {
  e.preventDefault();
  toogleSeeDetailsClosed();
}

seeDetailsCloseBtn.addEventListener("click", function (e) {
  e.preventDefault();
  toogleSeeDetailsClosed();
});

seeDetailsModalOverlay.addEventListener("click", function (e) {
  toogleSeeDetailsClosed();
});

// Mobile navbar
function openNavbar() {
  document.addEventListener("click", closeNavbar);
  mobileNavbarMenu.classList.add("open");
  mobileNavbarToogle.classList.add("open");
}

function closeNavbar() {
  document.removeEventListener("click", closeNavbar);
  mobileNavbarMenu.classList.remove("open");
  mobileNavbarToogle.classList.remove("open");
}

function toogleNavbar(ev) {
  ev.preventDefault();
  ev.stopPropagation();
  if (Array.from(mobileNavbarMenu.classList).includes("open")) {
    closeNavbar();
  } else {
    openNavbar();
  }
}

const mobileNavbarToogle = document.querySelector(".mobile-navbar-menu-toggle");
const mobileNavbarMenu = document.querySelector(".mobile-navbar-menu");

mobileNavbarToogle.addEventListener("click", toogleNavbar);

// Request demo primary cta in Navbar
function navbarRequestDemoCta(ev) {
  ev.preventDefault();
  scroll({
    top: 0,
    behavior: "smooth",
  });
  const subscribeFormCta = document.getElementsByClassName("subscribe-form")[0];
  subscribeFormCta.focus();
  subscribeFormCta.classList.remove("box-shadow-grow");
  setTimeout(() => subscribeFormCta.classList.add("box-shadow-grow"), 250);
  setTimeout(() => {
    subscribeFormCta.classList.remove("box-shadow-grow");
  }, 600);
}

Array.from(document.getElementsByClassName("request-demo-cta")).forEach(
  (domElement) => {
    domElement.addEventListener("click", navbarRequestDemoCta);
  }
);
