const partners = [
  {
    name: "SBI MF",
    logo: "assets/images/icon-sbimf.png",
    website: "https://etf.sbimf.com/",
  },
  {
    name: "HDFC AMC",
    logo: "assets/images/icon-hdfc.png",
    website: "https://www.hdfcfund.com/our-products/etf",
  },
  {
    name: "ABSL",
    logo: "assets/images/icon-adityabirla.png",
    website: "https://mutualfund.adityabirlacapital.com/etf/etf-transaction",
  },
  {
    name: "Nippon India ETF",
    logo: "assets/images/icon-nippon.png",
    website: "https://etf.nipponindiaim.com/Funds",
  },
  {
    name: "Axis MF",
    logo: "assets/images/icon-axismf.png",
    website: "https://www.axismf.com/mutual-funds/etfs",
  },
  {
    name: "DSP Mutual Fund",
    logo: "assets/images/icon-dspmf.png",
    website:
      "https://invest.dspim.com/etf-product/debt/liquid-etf/dsple-direct-daily-idcw",
  },
  {
    name: "Mirae Asset",
    logo: "assets/images/icon-mirae.png",
    website: "https://www.miraeassetmf.co.in/transact-in-etf",
  },
  {
    name: "Bharat Bond",
    logo: "assets/images/icon-bharatbond.png",
    website: "https://www.bharatbond.in/",
  },
  {
    name: "Motilal Oswal Securities",
    logo: "assets/images/icon-motilaloswalmf.png",
    website: "https://www.mostshares.com/",
  },
  {
    name: "UTI AMC",
    logo: "assets/images/icon-uti-mutual-funds.png",
    website: "https://www.utimf.com/mutual-fund-products/etfs/",
  },
  {
    name: "Business Today",
    logo: "assets/images/icon-businesstoday.png",
    website:
      "https://www.businesstoday.in/stocks/reliance-industries-ltd-reliance-share-price-361456",
  },
  {
    name: "Stockedge",
    logo: "assets/images/icon-stockedge.png",
    website:
      "https://web.stockedge.com/share/reliance-industries/4897?section=prices",
  },
  {
    name: "Tijori",
    logo: "assets/images/icon-tijori.png",
    website: "https://www.tijorifinance.com/in/portfolio/",
  },
  {
    name: "Tickertape",
    logo: "assets/images/icon-tickertape.png",
    website: "https://www.tickertape.in/stocks/reliance-industries-RELI",
  },
  {
    name: "Islamicly",
    logo: "assets/images/icon-islamicly.png",
    website: "https://www.islamicly.com/",
  },
  {
    name: "Fi Money",
    logo: "assets/images/icon-fimoney.png",
    website: "https://fi.money/",
  },
  {
    name: "GoalFi",
    logo: "assets/images/icon-goalfi.png",
    website: "https://www.goalfi.app/",
  },
  {
    name: "Kuvera",
    logo: "assets/images/icon-kuvera.png",
    website: "https://kuvera.in/explore/stocks/l/reliance-industries-ltd",
  },
  {
    name: "Tata Capital",
    logo: "assets/images/icon-tatacapital.png",
    website:
      "https://play.google.com/store/apps/details?id=com.tatacapital.moneyfy&hl=en_IN&gl=US",
  },
  {
    name: "Dezerv",
    logo: "assets/images/icon-dezerv.png",
    website: "https://www.dezerv.in/wealth-monitor/",
  },
  {
    name: "Cube",
    logo: "assets/images/icon-cube.png",
    website: "https://www.bankoncube.com/",
  },
  {
    name: "Elever",
    logo: "assets/images/icon-elever.png",
    website: "https://www.elever.app/",
  },
  {
    name: "ETF Junction",
    logo: "assets/images/icon-etfjunction.png",
    website: "https://etfjunction.com/",
  },
  {
    name: "ET Money",
    logo: "assets/images/icon-etmoney.png",
    website: "https://www.etmoney.com/",
  },
  {
    name: "Purnartha",
    logo: "assets/images/icon-purnartha.png",
    website: "https://www.purnartha.com/",
  },
  {
    name: "Green Portfolio",
    logo: "assets/images/icon-green-portfolio.png",
    website: "https://greenportfolio.co/smallcase-investment#smallcases",
  },
  {
    name: "Teji Mandi",
    logo: "assets/images/icon-tejimandi.png",
    website:
      "https://play.google.com/store/apps/details?id=com.tejimandi.android&hl=en_IN&gl=US",
  },
  {
    name: "PrimeInvestors",
    logo: "assets/images/icon-primeinvestor.png",
    website: "https://primeinvestor.in/portfolio-review-pro/",
  },
  {
    name: "Vivekam",
    logo: "assets/images/icon-vivekam.png",
    website: "https://www.vivekam.co.in/",
  },
  {
    name: "Algosmith",
    logo: "assets/images/icon-algosmith.png",
    website: "https://www.algosmith.in/",
  },
  {
    name: "Lotusdew",
    logo: "assets/images/icon-lotusdew.png",
    website: "https://www.lotusdew.in/",
  },
  {
    name: "Yadnya Academy",
    logo: "assets/images/icon-yadnaya-academy.png",
    website: "https://investyadnya.in/",
  },
  {
    name: "Wright Research",
    logo: "assets/images/icon-wright-research.png",
    website: "https://www.wrightresearch.in/portfolio/",
  },
  {
    name: "Samvitti Capital",
    logo: "assets/images/icon-samvitti-capital.png",
    website: "https://samvitticapital.com/",
  },
  {
    name: "Jarvis Invest",
    logo: "assets/images/icon-jarvis.png",
    website: "https://www.jarvisinvest.com/",
  },
  {
    name: "Fintoo",
    logo: "assets/images/icon-fintoo.png",
    website: "https://fintooinvest.in/",
  },
  {
    name: "Lamron Analysts",
    logo: "assets/images/icon-lamron.png",
    website: "https://smartvalues.co.in/public/landing-page",
  },
  {
    name: "Savart",
    logo: "assets/images/icon-savart.png",
    website: "https://play.google.com/store/apps/details?id=com.savart",
  },
  {
    name: "Clovek Wealth",
    logo: "assets/images/icon-clovek.png",
    website: "https://www.clovekwealth.com/",
  },
  {
    name: "Stockbox",
    logo: "assets/images/icon-stockbox.png",
    website: "https://stockboxtech.com/",
  },
  {
    name: "Savestment",
    logo: "assets/images/icon-savestment.png",
    website: "https://www.savestment.com/",
  },
  {
    name: "Scoutquest(Fundmap)",
    logo: "assets/images/icon-fundmap.png",
    website: "https://scoutquest.in",
  },
  {
    name: "Jama Wealth",
    logo: "assets/images/icon-jama-wealth.png",
    website: "https://www.jama.co.in/",
  },
  {
    name: "Marketsmojo",
    logo: "assets/images/icon-marketsmojo.png",
    website: "https://www.marketsmojo.com/",
  },
  {
    name: "Finmo",
    logo: "assets/images/icon-finmo.png",
    website: "https://uatmf.tofi.tech/",
  },
  {
    name: "Fintso",
    logo: "assets/images/icon-fintso.png",
    website: "https://www.fintso.com/",
  },
  {
    name: "Fundexpert",
    logo: "assets/images/icon-fundexpert.png",
    website: "https://www.fundexpertfintech.com/",
  },
  {
    name: "Vijaya Fintech",
    logo: "assets/images/icon-vijay-iprism.png",
    website: "http://vijyaiprism.com/",
  },
  {
    name: "Eqbac",
    logo: "assets/images/icon-eqbac.png",
    website: "https://eqbac.com/",
  },
  {
    name: "Moolaah",
    logo: "assets/images/icon-moolaah.png",
    website: "https://www.moolaah.com/",
  },
  {
    name: "IFANow",
    logo: "assets/images/icon-ifanow.png",
    website: "https://ifanow.com",
  },
  {
    name: "Centricity",
    logo: "assets/images/icon-centricity.png",
    website: "https://centricity.co.in/",
  },
  {
    name: "Capricorne Mindframe",
    logo: "assets/images/icon-capricorne-mindframe.png",
    website: "https://capricorne.in/Alpha-Brief-Case/",
  },
  {
    name: "Univest",
    logo: "assets/images/icon-univest.png",
    website: "https://univest.in/",
  },
  {
    name: "Liquide",
    logo: "assets/images/icon-liquide.png",
    website: "https://liquide.life/",
  },
  {
    name: "Investmint",
    logo: "assets/images/icon-investmint.png",
    website: "https://www.investmint.club/",
  },
  {
    name: "Investmates",
    logo: "assets/images/icon-investmates.png",
    website: "https://investmates.io/",
  },
  {
    name: "Covesto",
    logo: "assets/images/icon-covesto.png",
    website: "covesto.in",
  },
  {
    name: "Wylth",
    logo: "assets/images/icon-wylth.png",
    website: "https://www.wylth.com/",
  },
  {
    name: "Zerodha Fund House",
    logo: "assets/images/icon-zerodha-fund-house.png",
    website: "https://www.zerodhafundhouse.com/",
  },
  {
    name: "Indivest",
    logo: "assets/images/icon-indivest.png",
    website: "https://indivest.in/",
  },
  {
    name: "EquityPandit",
    logo: "assets/images/icon-equitypandit.png",
    website: "https://www.equitypandit.com/unicorn/",
  },
  // {
  //   name: "Moneycontrol",
  //   logo: "assets/images/icon-moneycontrol.png",
  //   website:
  //     "https://www.moneycontrol.com/india/stockpricequote/refineries/relianceindustries/RI",
  // },
  // {
  //   name: "VRO",
  //   logo: "assets/images/icon-vro.png",
  //   website: "https://www.valueresearchstocks.com/",
  // },
  // {
  //   name: "Encome",
  //   logo: "assets/images/icon-encome.png",
  //   website: "https://encome.io/",
  // },
  // {
  //   name: "JustDial",
  //   logo: "assets/images/icon-justdial.png",
  //   website:
  //     "https://play.google.com/store/apps/details?id=com.justdial.search&hl=en_IN&gl=US",
  // },
  // {
  //   name: "INDMoney",
  //   logo: "assets/images/icon-indmoney.png",
  //   website: "https://www.indmoney.com/about",
  // },
  // {
  //   name: "Mintgenie",
  //   logo: "assets/images/icon-mintgenie.png",
  //   website:
  //     "https://play.google.com/store/apps/details?id=com.mintgenie&hl=en_IN&gl=US",
  // },
  // {
  //   name: "Floatr",
  //   logo: "assets/images/icon-floatr.png",
  //   website:
  //     "https://play.google.com/store/apps/details?id=com.floatr.personal",
  // },
  // {
  //   name: "Moneyphi",
  //   logo: "assets/images/icon-moneyphi.png",
  //   website: "https://www.moneyphi.com/",
  // },
  // {
  //   name: "Finzard",
  //   logo: "assets/images/icon-finzard.png",
  //   website: "https://www.finzard.com/",
  // },
  // {
  //   name: "Finmapp",
  //   logo: "assets/images/icon-finmapp.png",
  //   website: "https://www.finmapp.com/",
  // },
  // {
  //   name: "Sahi Savings",
  //   logo: "assets/images/icon-sahi-savings.png",
  //   website: "https://sahisavings.com/",
  // },
  // {
  //   name: "Stockknocks",
  //   logo: "assets/images/icon-stockknocks.png",
  //   website: "https://www.stockknocks.com/",
  // },
  // {
  //   name: "Chhotastock",
  //   logo: "assets/images/icon-chhotastock.png",
  //   website: "https://chhotastock.com/",
  // },
  // {
  //   name: "MoneyWorks4Me",
  //   logo: "assets/images/icon-moneyworks4me.png",
  //   website: "https://www.moneyworks4me.com/",
  // },
  // {
  //   name: "CapitalMind",
  //   logo: "assets/images/icon-capitalmind.png",
  //   website: "https://www.capitalmind.in/",
  // },
  // {
  //   name: "Swing Trader",
  //   logo: "assets/images/icon-swingtrader.png",
  //   website: "https://swingtraderindia.com/",
  // },
  // {
  //   name: "Stock Axis",
  //   logo: "assets/images/icon-stockaxis.png",
  //   website: "https://stockaxis.com/EML/Index.aspx?utm_source=google",
  // },
  // {
  //   name: "Bridgeweave",
  //   logo: "assets/images/icon-bridgeweave.png",
  //   website: "https://investorai.co.uk/",
  // },
  // {
  //   name: "MarketSmith India",
  //   logo: "assets/images/icon-marketsmith.png",
  //   website: "https://marketsmithindia.com/",
  // },
  // {
  //   name: "Estee Advisors",
  //   logo: "assets/images/icon-estee.png",
  //   website: "https://pro.gulaq.com/dashboard",
  // },
  // {
  //   name: "Valuestocks",
  //   logo: "assets/images/icon-value-stocks.png",
  //   website: "https://www.valuestocks.in/",
  // },
  // {
  //   name: "GCIA",
  //   logo: "assets/images/icon-gcia.png",
  //   website: "https://www.guardiancapital.com/",
  // },
  // {
  //   name: "ASQI",
  //   logo: "assets/images/icon-makemyfund.png",
  //   website: "https://asqisys.com/index.html",
  // },
  // {
  //   name: "Goalteller",
  //   logo: "assets/images/icon-goalteller.png",
  //   website: "https://goalteller.com/",
  // },
  // {
  //   name: "Supermoney",
  //   logo: "assets/images/icon-supermoney.png",
  //   website:
  //     "https://play.google.com/store/apps/details?id=com.piadvisors&pli=1",
  // },
  // {
  //   name: "Finflo",
  //   logo: "assets/images/icon-finflo.png",
  //   website: "https://actlogica.com/home/products/finflo-enterprise/",
  // },
  // {
  //   name: "SBNRI",
  //   logo: "assets/images/icon-sbnri.png",
  //   website: "https://sbnri.com/",
  // },
  // {
  //   name: "Propreturns",
  //   logo: "assets/images/icon-propreturns.png",
  //   website: "https://www.propreturns.com/reits",
  // },
  // {
  //   name: "Flipitnews",
  //   logo: "assets/images/icon-flipitnews.png",
  //   website: "https://flipitnews.com/",
  // },
  // {
  //   name: "Threedots",
  //   logo: "assets/images/icon-threedots.png",
  //   website: "https://www.threedots.app/",
  // },
  // {
  //   name: "Trinkerr",
  //   logo: "assets/images/icon-trinkerr.png",
  //   website: "https://trinkerr.com/",
  // },
  // {
  //   name: "Multipie",
  //   logo: "assets/images/icon-multipie.png",
  //   website: "https://www.multipie.co/",
  // },
  // {
  //   name: "Northloop",
  //   logo: "assets/images/icon-northloop.png",
  //   website: "https://www.mesha.club/",
  // },
  // {
  //   name: "Clubfolio",
  //   logo: "assets/images/icon-flowclub.png",
  //   website: "https://www.flowclub.in/",
  // },
  // {
  //   name: "Mahogha",
  //   logo: "assets/images/icon-mahogha.png",
  //   website: "https://mahogha.com/",
  // },
  // {
  //   name: "iTribe",
  //   logo: "assets/images/icon-itribe.png",
  //   website: "https://itribe.in/",
  // },
  // {
  //   name: "Tradebox",
  //   logo: "assets/images/icon-tradebox.png",
  //   website:
  //     "https://play.google.com/store/apps/details?id=com.gw.tradebox.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
  // },
  // {
  //   name: "Onfinance",
  //   logo: "assets/images/icon-onfinance.png",
  //   website: "https://play.google.com/store/apps/details?id=com.onfinance.app",
  // },
  // {
  //   name: "Station91",
  //   logo: "assets/images/icon-station91.png",
  //   website: "https://play.google.com/store/apps/details?id=com.station91",
  // },
  // {
  //   name: "Zupay",
  //   logo: "assets/images/icon-zupay.png",
  //   website: "https://www.zupay.in/",
  // },
  // {
  //   name: "Waya",
  //   logo: "assets/images/icon-waya.png",
  //   website: "https://thewaya.com/",
  // },
  // {
  //   name: "StockPe",
  //   logo: "assets/images/icon-stockpe.png",
  //   website: "https://stockpe.in/",
  // },
  // {
  //   name: "Quantonaut",
  //   logo: "assets/images/icon-quantonaut.png",
  //   website: "https://quantonaut.com/",
  // },
];
const win1 = document.getElementById("window1");
const win2 = document.getElementById("window2");
partners.forEach((partner, index) => {
  const partnerElement = `
          <figure class="logo-img pane">
            <a
              href=${partner.website}
              target="_blank"
              rel="noopener noreferrer"
              class="logo-img-link"
            >
              <img
                src=${partner.logo}
                alt=${partner.name}
                class="img-fluid"
              />
            </a>
          </figure>`;
  win1.innerHTML += partnerElement;
  win2.innerHTML += partnerElement;
});
const windowWidth =
  screen.width > 580
    ? 180 * Math.ceil(partners.length / 3)
    : 120 * Math.ceil(partners.length / 3);
win1.style.width = `${windowWidth}px`;
win2.style.width = `${windowWidth}px`;
